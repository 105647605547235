import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Import } from '../models/import.model';
import { UrlsService } from '../globals/urls.service'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {

	token:any;

  constructor(private http: HttpClient, private urls: UrlsService) { }

  postSecure(url, data){
  	const httpOptions = {
  		headers: new HttpHeaders({
  			'Content-Type': 'application/json',
  			'Access-Control-Allow-Origin': '*',
  			'Authorization': 'Bearer '+this.sendToken()
  		})
  	};
  	return this.http.post(url, data, httpOptions);
  }
  
  upload(url, data){
  	const httpOptions = {
  		headers: new HttpHeaders({
  			'Access-Control-Allow-Origin': '*',
  			'Authorization': 'Bearer '+this.sendToken()
  		})
  	};
  	return this.http.post(url, data, httpOptions);
  }

  postOpen(url, data){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.post(url, data, httpOptions);
  }
  
  get(url){
	const httpOptions = {
  		headers: new HttpHeaders({
  			'Content-Type': 'application/json',
  			'Access-Control-Allow-Origin': '*',
  			'Authorization': 'Bearer '+this.sendToken()
  		})
	};
	
	return this.http.get(url, httpOptions);
  }
  
  download(url){
	const httpOptions = {
		responseType: 'blob' as 'json',
  		headers: new HttpHeaders({
  			'Access-Control-Allow-Origin': '*',
  			'Authorization': 'Bearer '+this.sendToken()
  		})
	};
	
	return this.http.get(url, httpOptions);
  }
  
  signout(){
	this.token = "";
  }
  
  getToken(token){
  	this.token = token;
  }

  sendToken(){
  	return localStorage.getItem("auth_token");
  }

}

import { Injectable } from '@angular/core';
import { AuthGroup } from '../models/authorization.types';
import { ApiServiceService } from './api-service.service';
import { UrlsService } from '../globals/urls.service';
import { Observable } from 'rxjs';
import { of as ObservableOf } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

	permissions: Array<string>;
	
  constructor(private apiService: ApiServiceService, private urls: UrlsService) { }
  
  hasPermission(authGroup: AuthGroup){
	if(this.permissions && this.permissions.find(permission => { return permission === authGroup; })){
		return true;
	}
	return false;
  }
  
  initializePermissions(): Promise<any>{
	return new Promise((resolve, reject) =>{
		this.apiService.get(this.urls.PERMISSIONS).subscribe(result =>{
  			this.permissions = result['permissions'];
  			resolve(true);
	  	}, error => {
	  		console.error("Error fetching permissions");
	  		resolve(false);
	  	});
	});
   
  }
  
  signout(){
		this.permissions = [];
  }
}


import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlsService {

	private base_url:string = "https://firecracker.mynationbuilder.org/api/";
	
	public UPLOAD_DIRECTORY:string = this.base_url + "attachment";
	
	public LOGIN:string = this.base_url + "login";
	public REGISTER:string = this.base_url + "register";
	public DASHBOARD:string = this.base_url + "dashboard";
	public DASHBOARD_CHARTS:string = this.base_url + "dashboardcharts";
	public PERMISSIONS:string = this.base_url + "permissions";
	public FORGOT_PASSWORD:string = this.base_url + "forgot_password";
	public VERIFY_RESET_CODE:string = this.base_url + "verify_code";
	public RESET_PASSWORD:string = this.base_url + "reset_password";
	
	public USER_PROFILE:string = this.base_url + "profile";
	public USER_ATTENDANCE:string = this.base_url + "attendance";
	public USER_CHARITY:string = this.base_url + "charity";
	public USER_FINALCALL:string = this.base_url + "finalcall";

	public ADMIN_USERS:string = this.base_url + "admin/users";
	public ADMIN_PROFILE:string = this.base_url + "admin/profile";
	public ADMIN_IMPORTS:string = this.base_url + "admin/imports";
	public ADMIN_ROLES:string = this.base_url + "admin/roles";
	public ADMIN_ADD_ROLE:string = this.base_url + "admin/addrole";
	public ADMIN_REMOVE_ROLE:string = this.base_url + "admin/removerole";
	public ADMIN_REGIONS:string = this.base_url + "admin/regions";
	public ADMIN_ADD_REGION:string = this.base_url + "admin/addregion";
	public ADMIN_REMOVE_REGION:string = this.base_url + "admin/removeregion";
	public ADMIN_MOSQUES:string = this.base_url + "admin/mosques";
	public ADMIN_ADD_MOSQUE:string = this.base_url + "admin/addmosque";
	public ADMIN_REMOVE_MOSQUE:string = this.base_url + "admin/removemosque";

	public NATIONAL_REGIONS:string = this.base_url + "national/regions";
	public NATIONAL_MOSQUES:string = this.base_url + "national/mosques";
	public NATIONAL_FOI:string = this.base_url + "national/foi";
	public NATIONAL_PROFILE:string = this.base_url + "national/profile";
	public NATIONAL_PROFILE_ATTENDANCE:string = this.base_url + "national/profileattendance";
	public NATIONAL_PROFILE_CHARITY:string = this.base_url + "national/profilecharity";
	public NATIONAL_REPORTS_FINALCALL:string = this.base_url + "national/finalcall";
	public NATIONAL_REPORTS_FINALCALL_MOSQUES:string = this.base_url + "national/finalcall-mosques";
	public NATIONAL_REPORTS_FINALCALL_CITIES_ORDERS:string = this.base_url + "national/finalcall_cities_orders";
	public NATIONAL_REPORTS_REGIONLIST:string = this.base_url + "national/regionlist";
	public NATIONAL_REPORTS_REGIONROWS:string = this.base_url + "national/regionrows";
	public NATIONAL_REPORTS_ATTENDANCE:string = this.base_url + "national/attendance";
	public NATIONAL_GOALS:string = this.base_url + "national/goals";
	public NATIONAL_GOALS_ADD_FINALCALL:string = this.base_url + "national/goaladdfcn";
	public NATIONAL_GOALS_GET_FINALCALL:string = this.base_url + "national/goalgetfcn";
	public NATIONAL_GOALS_ADD_NATIONALSECURITY:string = this.base_url + "national/goaladdnsc";
	public NATIONAL_GOALS_GET_NATIONALSECURITY:string = this.base_url + "national/goalgetnsc";
	public NATIONAL_MOSQUE_NAME:string = this.base_url + "national/mosquename";
	public NATIONAL_REPORTS_LOCAL_OVERVIEW = this.base_url + "national/localoverview";
	public NATIONAL_SEND_MESSAGE = this.base_url + "national/sendmessage";
	public NATIONAL_FINAL_CALL_SETTINGS = this.base_url + "national/finalcallsettings";
	public NATIONAL_FINAL_CALL_ORDERS = this.base_url + "national/finalcallorders";
	public NATIONAL_GET_ORDER = this.base_url + "national/get_finalcall_order";
	public NATIONAL_UPDATE_ORDER = this.base_url + "national/finalcall_update_order";
	public NATIONAL_FINAL_CALL_ORDER_LINES = this.base_url + "national/finalcall_order_lines";
	public NATIONAL_EXPORT_FINAL_CALL_ORDER_LINES = this.base_url + "national/export_finalcall_order_lines";
	public NATIONAL_EXPORT_FINALCALL_CITIES_ORDERS = this.base_url + "national/export_finalcall_cities_orders";
	public NATIONAL_REPORTS_FIRST_TIME_GUESTS = this.base_url + "national/report-first-time-guests";
	public NATIONAL_EXPORT_FIRST_TIME_GUESTS = this.base_url + "national/export-first-time-guests";
	
	public REGIONAL_MOSQUES:string = this.base_url + "regional/mosques";
	public REGIONAL_FOI:string = this.base_url + "regional/foi";
	public REGIONAL_PROFILE:string = this.base_url + "regional/profile";
	public REGIONAL_PROFILE_ATTENDANCE:string = this.base_url + "regional/profileattendance";
	public REGIONAL_PROFILE_CHARITY:string = this.base_url + "regional/profilecharity";
	public REGIONAL_REPORTS_OVERVIEWROWS:string = this.base_url + "regional/overviewrows";
	public REGIONAL_FINAL_CALL_ORDER_LINES:string = this.base_url + "regional/finalcall_order_lines";
	public REGIONAL_GET_ORDER:string = this.base_url + "regional/get_finalcall_order";
	public REGIONAL_GET_ORDER_TOTAL = this.base_url + "regional/get_finalcall_order_total";
	public REGIONAL_GET_ORDER_FOI = this.base_url + "regional/get_finalcall_foi";
	public REGIONAL_APPROVE_ORDER = this.base_url + "regional/approve_order";
	public REGIONAL_REJECT_ORDER = this.base_url + "regional/reject_order";
	
	public LOCAL_MYCITY:string = this.base_url + "local/mycity";
	public LOCAL_PROFILE:string = this.base_url + "local/profile";
	public LOCAL_PROFILE_ATTENDANCE:string = this.base_url + "local/profileattendance";
	public LOCAL_PROFILE_CHARITY:string = this.base_url + "local/profilecharity";
	public LOCAL_PROFILE_FINALCALL:string = this.base_url + "local/profilefinalcall";
	public LOCAL_MOSQUES:string = this.base_url + "local/mosques";
	public LOCAL_OVERVIEW_REPORTS = this.base_url + "local/overview";
	public LOCAL_SUBMIT_OVERVIEW = this.base_url + "local/submitoverview";
	public LOCAL_CHECK_OVERVIEW_DATE = this.base_url + "local/checkoverviewdate";
	public LOCAL_GET_OVERVIEW = this.base_url + "local/viewoverview";
	public LOCAL_REPORT_ATTENDANCE = this.base_url + "local/reportattendance";
	public LOCAL_REPORT_ATTENDANCE_VIEW = this.base_url + "local/reportattendanceview";
	public LOCAL_FINAL_CALL_ORDERS = this.base_url + "local/finalcallorders";
	public LOCAL_GET_ORDER = this.base_url + "local/get_finalcall_order";
	public LOCAL_GET_ORDER_FOI = this.base_url + "local/get_finalcall_foi";
	public LOCAL_SAVE_FCN_ORDER = this.base_url + "local/save_finalcall_order";
	public LOCAL_SAVE_FOI_FCN_ORDER = this.base_url + "local/save_foi_finalcall_order";
	public LOCAL_SAVE_EXTRA_FCN_ORDER = this.base_url + "local/save_extra_finalcall_order";
	public LOCAL_SAVE_FCN_ORDER_PAYMENT = this.base_url + "local/save_finalcall_order_payment";
	public LOCAL_GET_ORDER_TOTAL = this.base_url + "local/get_finalcall_order_total";
	public LOCAL_FCN_DEPOSIT_UPLOAD = this.base_url + "local/fcn_deposit_upload";
	public LOCAL_GET_ORDER_SUMMARYS = this.base_url + "local/get_finalcall_order_summary";
	public LOCAL_GET_PAPERS_FOI = this.base_url + "local/finalcall_papers_foi";
	public LOCAL_GET_ZEROS_FOI = this.base_url + "local/finalcall_zeros_foi";
	public LOCAL_REPORT_INCIDENT_TYPES = this.base_url + "local/report-incident-types";
	public LOCAL_REPORT_INCIDENT_DETAIL_TYPES = this.base_url + "local/report-incident-detail-types";
	public LOCAL_REPORTS_FIRST_TIME_GUESTS = this.base_url + "local/report-first-time-guests";

  constructor() { }
}

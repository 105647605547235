import { Routes } from '@angular/router';
import { AuthGuardGuard } from './guards/auth-guard.guard';

import { AdminLayoutComponent } from './core';
import { AuthLayoutComponent } from './core';
import { BlankLayoutComponent } from './core';

export const AppRoutes: Routes = [{
  path: '',
  component: AuthLayoutComponent,
  children: [{
    path: '',
    loadChildren: './authentication/authentication.module#AuthenticationModule'
  }, {
	path: '',
	component: BlankLayoutComponent,
	children: [{
    path: 'shop',
	canActivate: [AuthGuardGuard],
	data: { auth: 'SHOP' },
    loadChildren: './shop/shop.module#ShopModule'
	}]
  },
  {
  path: '',
  component: AdminLayoutComponent,
  children: [{
    path: 'dashboard',
	canActivate: [AuthGuardGuard],
	data: { auth: 'LOGIN' },
    loadChildren: './dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'administration',
	canActivate: [AuthGuardGuard],
	data: { auth: 'ADMIN' },
    loadChildren: './administration/administration.module#AdministrationModule'
  },
  {
    path: 'national',
	canActivate: [AuthGuardGuard],
	data: { auth: 'NATIONAL' },
    loadChildren: './national/national.module#NationalModule'
  },
  {
    path: 'regional',
	canActivate: [AuthGuardGuard],
	data: { auth: 'REGIONAL' },
    loadChildren: './regional/regional.module#RegionalModule'
  },
  {
    path: 'local',
	canActivate: [AuthGuardGuard],
	data: { auth: 'LOCAL' },
    loadChildren: './local/local.module#LocalModule'
  },
  {
    path: 'user',
	canActivate: [AuthGuardGuard],
	data: { auth: 'LOGIN' },
    loadChildren: './user/user.module#UserModule'
  },
  {
    path: 'docs',
    loadChildren: './docs/docs.module#DocsModule'
  }]
},{
    path: 'error',
    loadChildren: './error/error.module#ErrorModule'
  }]
}, {
  path: '**',
  redirectTo: 'error/404'
}];
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthorizationService } from '../services/authorization.service';
import { AuthGroup } from '../models/authorization.types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {

	constructor(protected router: Router, protected authorizationService: AuthorizationService) { }
	
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.hasRequiredPermission(next.data['auth']);
  }
  
	protected hasRequiredPermission(authGroup: AuthGroup): Promise<boolean> | boolean {
		if(this.authorizationService.permissions){
            if(authGroup){
                return this.authorizationService.hasPermission(authGroup);
            }else{
                return this.authorizationService.hasPermission(null);
            }
        }else{
            const promise = new Promise<boolean>((resolve, reject) =>{
                this.authorizationService.initializePermissions()
                    .then(() => {
                        if(authGroup){
                            resolve(this.authorizationService.hasPermission(authGroup));
                        } else {
                            resolve(this.authorizationService.hasPermission(null));
                        }
                    })
                    .catch(() => {
                        resolve(false);
                    });
            });
            return promise;
        }
	}
}

import { Injectable } from '@angular/core';
import { AuthorizationService } from '../../services/authorization.service';
import { AuthGroup } from '../../models/authorization.types';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
  role: string;
}

const MENUITEMS = [
  {
    state: 'dashboard',
    name: 'HOME',
    type: 'link',
    icon: 'basic-accelerator',
	role: 'LOGIN'
  },
  {
    state: 'administration',
    name: 'Administration',
    type: 'sub',
    icon: 'basic-lock-open',
	role: 'ADMIN',
    children: [
      {
        state: 'users',
        name: 'Users'
      },
      {
        state: 'imports',
        name: 'Import Status'
      },
      {
        state: 'roles',
        name: 'Roles'
      },
    /*  {
        state: 'options',
        name: 'Options'
      }, */
    ]
  },
  {
    state: 'national',
    name: 'National',
    type: 'sub',
    icon: 'basic-flag2',
	role: 'NATIONAL',
    children: [
      {
        state: 'finalcall',
        name: 'Final Call'
      },
	  {
        state: 'regions',
        name: 'Regions'
      },
      {
        state: 'mosques',
        name: 'Mosques'
      },
      {
        state: 'reports',
        name: 'Reports'
      },
	  {
        state: 'goals',
        name: 'Goals'
      },
      {
        state: 'messaging',
        name: 'Send Message',
		role: 'NATIONAL_MESSAGING'
      },
    ]
  },
  {
    state: 'regional',
    name: 'Regional',
    type: 'sub',
    icon: 'basic-flag2',
	role: 'REGIONAL',
    children: [
	   {
        state: 'finalcall',
        name: 'Final Call'
      },
      {
        state: 'mosques',
        name: 'Mosques'
      },
      {
        state: 'reports',
        name: 'Reports'
      },
     /** {
        state: 'messaging',
        name: 'Send Message'
      }, */
    ]
  },
  {
    state: 'local',
    name: 'Local',
    type: 'sub',
    icon: 'basic-flag2',
	role: 'LOCAL',
    children: [
		{
			state: 'finalcall',
			name: 'Final Call'
		},
      {
        state: 'mycity',
        name: 'My City'
      },
      {
        state: 'reports',
        name: 'Reports'
      },
    ]
  },
  {
    state: 'shop',
    name: 'Shop',
    type: 'link',
    icon: 'basic-flag2',
	role: 'SHOP'
  },
 
];

@Injectable()
export class MenuService {

	constructor(protected authorizationService: AuthorizationService) { }
	
  getAll(): Menu[] {
    return MENUITEMS;
  }
  
  showMenuItem(authGroup: AuthGroup){
	return this.authorizationService.hasPermission(authGroup);
  }
  
  showChildMenuItem(authGroup: AuthGroup){
	if( authGroup != null ){
		return this.authorizationService.hasPermission(authGroup);
	}else return true;
	
  }
}
